import './App.css';
import NavBar from './components/NavBar';
import Form from './components/Form';
import Withus from './components/Bookwithus';
import Services from './components/Services';
import Aboutprovider from './components/Aboutprovider';
import VideoComponent from './components/Video';
import Footer from './components/Footer';
import Ourfleet from './components/Ourfleet';
import Ourprojects from './components/Ourprojects';
import CommentComponent from './components/Comments';
import OurClients from './components/Ourclients';
import Location from './assets/location_Icon.png'
import Date from './assets/date_Icon.png'
import Time from './assets/Time_Icon.png'
import Passenger from './assets/Passenger_Icon.png'
import Currency from './assets/Currency_Icon.png'
import commentpic1 from './assets/commentpic1.png'
import commentpic2 from './assets/commentpic2.png'
import commentpic3 from './assets/commentpic3.png'



function App() {
  const form_inputs = [
    {'name':'Airport Transfer', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up', 'type':'location', 'id':'from', 'placeholder':'Airport name'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'Pick Up Date'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'Pick Up Time'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'category', 'placeholder':'USD'}]]},
    {'name':'Hourly', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'Pick Up Date'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'Pick Up Time'}],[{'for':'hours', 'src':Passenger, 'label':'Pick Up Hours', 'type':'number', 'id':'hours', 'placeholder':'hours'}, {'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'category', 'placeholder':'USD'}]]},
    {'name':'Full Day', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'Pick Up Date'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'Pick Up Time'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'category', 'placeholder':'USD'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}]]},
    {'name':'Point to Point', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'Pick Up Date'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'Pick Up Time'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'category', 'placeholder':'USD'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}]]}
  ]

  const commentsList = [
    {'rating':4, 'image':commentpic1, 'name':'Merry Lana', 'comment':"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. Lorem Ipsum is simply dummy text of the printing."},
    {'rating':4, 'image':commentpic2, 'name':'Robert king', 'comment':'The second comment goes here. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
    {'rating':4, 'image':commentpic3, 'name':'Ken Hunter', 'comment':'And here is the third comment. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.'},
    {'rating':4, 'image':commentpic1, 'name':'Merry Lana', 'comment':'Comment number four. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'},
    {'rating':4, 'image':commentpic2, 'name':'Merry Lana', 'comment':'Fifth comment in the list. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
    {'rating':4, 'image':commentpic3, 'name':'Merry Lana', 'comment':'Sixth comment. Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.'},
    // Add more comments as needed
  ];
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
        <Form form_inputs={form_inputs}/>
        <Withus />
        <Services />
        <Aboutprovider/>
        <VideoComponent/>
        <Ourfleet/>
        <Ourprojects/>
        <CommentComponent comments={commentsList}/>
        <OurClients/>
        <Footer/>
      </header>
    </div>
  );
}

export default App;
