import React, { useState } from 'react';
import '../CSS/Ourfleet.css';
import audiA4 from '../assets/audiA4.png'
import audiA6 from '../assets/AudiA6.png'
import audiA8 from '../assets/audiA8.png'
import marcedesEclass from '../assets/marcedes.png'
import marcedesSclass from '../assets/marcedesSclass.png'
import BMW5 from '../assets/BMW5.png'
import BMW7 from '../assets/BMW7.png'
import Touran from '../assets/Tourn.png'
import zafria from '../assets/zafira.png'

const Ourfleet = () => {
    const Economy = [{'name':'Audi A4','image':audiA4 , 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Volkswagen Touran','image':Touran,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Opel Zafira','image':zafria,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}];
    const Business = [{'name':'Mercedes E Class','image':marcedesEclass ,'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Audi A6','image':audiA6,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'BMW 5 Series','image':BMW5,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}];
    const First_class = [{'name':'Mercedes S Class','image':marcedesSclass, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'BMW 7 Series','image':BMW7, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Audi A8','image':audiA8, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}]
    const [selectedbtn, Setselectedbtn] = useState('Economy');
    const [selectedarray, Setselectedarray] = useState(Economy)

    const handleclick = (value, array11) => {
        Setselectedbtn(value)
        Setselectedarray(array11)
    }

    return (
        <div className='Ourfleet_main_container'>
            <div className='upper_div'>
                <div className='left_div'>
                    <h1>Our <span>fleet</span></h1>
                    <p>Lorem Ipsum is simply dummy text of the printing.</p>
                </div>
                <div className='right_div'>
                    <button className={selectedbtn === 'Economy' ? 'activebtn' : ''} onClick={() => handleclick('Economy', Economy)}>Economy</button>
                    <button className={selectedbtn === 'Business' ? 'activebtn' : ''} onClick={() => handleclick('Business', Business)}>Business</button>
                    <button className={selectedbtn === 'First_class' ? 'activebtn' : ''} onClick={() => handleclick('First_class', First_class)}>First Class</button>
                </div>
            </div>
            <div className='lower_div'>
                {selectedarray.map((image1, index) => (
                    <div key={index} className='lower_div_item'>
                        <h1>{image1.name}</h1>
                        <img src={image1.image} alt={`Image ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Ourfleet;
