// import React, { useState } from 'react';
// import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
// const libraries = ['places'];

// const MapWithAutoComplete =() => {
//   const [place, setPlace] = useState(null);

//   const onPlaceChanged = async () => {
//     if (place !== null && place.getPlace() !== undefined) {
//       const location = place.getPlace().geometry.location;
//       console.log('Place changed:', location.lat(), location.lng());
//     }
//   };

//   return (
//     <LoadScript
//       googleMapsApiKey="AIzaSyBM9mDE8ep8CZ5HDhVyr5OiIkre3lE0Hhs"
//       libraries={libraries}
//     >
//       <GoogleMap
//         center={{ lat: 0, lng: 0 }}
//         zoom={2}
//       >
//         <Autocomplete
//           onLoad={(autocomplete) => setPlace(autocomplete)}
//           onPlaceChanged={onPlaceChanged}
//         >
//           <input
//             type="text"
//             placeholder="Enter a location"
//             style={{
//               boxSizing: 'border-box',
//               border: '1px solid transparent',
//               width: '240px',
//               height: '32px',
//               padding: '0 12px',
//               borderRadius: '3px',
//               boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
//               fontSize: '14px',
//               outline: 'none',
//               textOverflow: 'ellipsis',  // Corrected property value
//               position: 'absolute',
//               left: '50%',
//               marginLeft: '-120px',
//             }}
//           />
//         </Autocomplete>
//       </GoogleMap>
//     </LoadScript>
//   );
// };

// export default MapWithAutoComplete;

import React from 'react';
import NavBar from '../components/NavBar';
import Inputfields from '../components/Inputfields';
import Footer from '../components/Footer';
// import CarsCards from '../components/Carscards';
import Location from '../assets/location_Icon.png'
import Date from '../assets/date_Icon.png'
import Time from '../assets/Time_Icon.png'
import Passenger from '../assets/Passenger_Icon.png'
import Currency from '../assets/Currency_Icon.png'
import audiA4 from '../assets/audiA4.png'
import audiA6 from '../assets/AudiA6.png'
import audiA8 from '../assets/audiA8.png'
import marcedesEclass from '../assets/marcedes.png'
import marcedesSclass from '../assets/marcedesSclass.png'
import BMW5 from '../assets/BMW5.png'
import BMW7 from '../assets/BMW7.png'
import Touran from '../assets/Tourn.png'
import zafria from '../assets/zafira.png'


const form_inputs = [
    {'name':'Airport Transfer', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up', 'type':'location', 'id':'from', 'placeholder':'Airport name'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'Pick Up Date'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'Pick Up Time'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'currency', 'placeholder':'USD'}],[{'for':'fare', 'src':Currency, 'label':'Fare', 'type':'Currency', 'id':'Currency', 'placeholder':'USD'}],[{'for':'Customer ID', 'src':Location, 'label':'Customer ID', 'type':'text', 'id':'CustomerID', 'placeholder':'Customer ID'}]]},
    {'name':'Hourly', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'MM-DD-YYYY'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'10:01'}],[{'for':'hours', 'src':Passenger, 'label':'Pick Up Hours', 'type':'number', 'id':'hours', 'placeholder':'hours'}, {'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'currency', 'placeholder':'USD'}],[{'for':'fare', 'src':Currency, 'label':'Fare', 'type':'Currency', 'id':'Currency', 'placeholder':'USD'}],[{'for':'Customer ID', 'src':Location, 'label':'Customer ID', 'type':'text', 'id':'CustomerID', 'placeholder':'Customer ID'}]]},
    {'name':'Full Day', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'MM-DD-YYYY'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'10:01'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'currency', 'placeholder':'USD'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}],[{'for':'fare', 'src':Currency, 'label':'Fare', 'type':'Currency', 'id':'Currency', 'placeholder':'USD'}],[{'for':'Customer ID', 'src':Location, 'label':'Customer ID', 'type':'text', 'id':'CustomerID', 'placeholder':'Customer ID'}]]},
    {'name':'Point to Point', 'inputs':[[{'for':'from', 'src':Location, 'label':'Pick Up Point', 'type':'location', 'id':'from', 'placeholder':'Address, Hotel, Airport'},{'for':'To', 'src':Location, 'label':'Drop Off Location', 'type':'location', 'id':'to', 'placeholder':'Address, Hotel, Airport'}],[{'for':'date', 'src':Date, 'label':'Pick Up Date', 'type':'date', 'id':'date', 'placeholder':'MM-DD-YYYY'},{'for':'time', 'src':Time, 'label':'Pick Up Time', 'type':'time', 'id':'time', 'placeholder':'10:01'}],[{'for':'catgory', 'src':Currency, 'label':'Choose Vehicle Category', 'type':'category', 'value':['Economy', 'Business', 'First Class'], 'id':'currency', 'placeholder':'USD'}],[{'for':'passenger', 'src':Passenger, 'label':'Passangers', 'type':'number', 'id':'passenger', 'placeholder':'1 Passenger'}],[{'for':'fare', 'src':Currency, 'label':'Fare', 'type':'Currency', 'id':'Currency', 'placeholder':'USD'}],[{'for':'Customer ID', 'src':Location, 'label':'Customer ID', 'type':'text', 'id':'CustomerID', 'placeholder':'Customer ID'}]]}
  ]
  const Carsdata = [
    {'type': 'Ecocnomy', 'data':[{'name':'Audi A4','image':audiA4 , 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Volkswagen Touran','image':Touran,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Opel Zafira','image':zafria,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}]},
    {'type': 'Buisness', 'data':[{'name':'Mercedes E Class','image':marcedesEclass ,'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Audi A6','image':audiA6,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'BMW 5 Series','image':BMW5,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}]},
    {'type': 'First_class', 'data':[{'name':'Mercedes S Class','image':marcedesSclass, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'BMW 7 Series','image':BMW7, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Audi A8','image':audiA8, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}]}
  ];
  // const Economy = [{'name':'Audi A4','image':audiA4 , 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Volkswagen Touran','image':Touran,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Opel Zafira','image':zafria,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}];
  // const Business = [{'name':'Mercedes E Class','image':marcedesEclass ,'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Audi A6','image':audiA6,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'BMW 5 Series','image':BMW5,  'type':'Sedan', 'example':' Toyota Prius', 'max':3}];
  // const First_class = [{'name':'Mercedes S Class','image':marcedesSclass, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'BMW 7 Series','image':BMW7, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}, {'name':'Audi A8','image':audiA8, 'type':'Sedan', 'example':' Toyota Prius', 'max':3}]
function BookingPage() {
    return (
        <div className="booking_page">
          <NavBar />
            <Inputfields selectedBtn={form_inputs[0]} carsData={Carsdata}/>
            {/* <CarsCards carsData={Carsdata}/> */}
            <Footer/>
        </div>
      );
}

export default BookingPage;