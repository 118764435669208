import React, { useState } from 'react';
import '../CSS/Navbar.css'

function NavBar() {
  const [selectedOption, setSelectedOption] = useState(null);

  const showUnderline = (element) => {
    setSelectedOption(element.innerText);
  };

  return (
    <div className="Navbar_App">
      <header className="Navbar_App_header">
        <nav>
          <a href="#home" onClick={(e) => showUnderline(e.target)} className={selectedOption === 'Client Login' ? 'active' : ''}>
            Client Login
            {selectedOption === 'Client Login' && <span className="underline"></span>}
          </a>
          <a href="#about" onClick={(e) => showUnderline(e.target)} className={selectedOption === 'Signup' ? 'active' : ''}>
            Signup
            {selectedOption === 'Signup' && <span className="underline"></span>}
          </a>
          <select onChange={(e) => showUnderline(e.target)}>
            <option value="#contact">Add-On Services</option>
            <option value="#location">Location</option>
            {/* Add more options as needed */}
          </select>
          <a href="#services" onClick={(e) => showUnderline(e.target)} className={selectedOption === 'About Us' ? 'active' : ''}>
            About Us
            {selectedOption === 'About Us' && <span className="underline"></span>}
          </a>
          <a href="#services" onClick={(e) => showUnderline(e.target)} className={selectedOption === 'Contact Us' ? 'active' : ''}>
            Contact Us
            {selectedOption === 'Contact Us' && <span className="underline"></span>}
          </a>
        </nav>
      </header>
    </div>
  );
}

export default NavBar;
