import React from 'react';
import '../CSS/Footer.css';
import logo from '../assets/Logo.png'
import facebook from '../assets/facebook.png'
import instagram from '../assets/instagram.png'
import linkedin from '../assets/linkedin.png'

const Footer = () => {
    return (
        <div className='main_footer_container'>
            <div className="footer_main_body">
                <div className="inner-div1">
                    <img src={logo} alt="" />
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                </div>
                <div className="inner-div2">
                    <p className='title'>Company</p>
                    <p>Add-On-Services</p>
                    <p>About Us</p>
                    <p>Our Fleet</p>
                    <p>Booking Conditions</p>
                    <p>Our Projects</p>
                    <p>Clients Testimonials</p>
                    <p>Our Clients</p>
                </div>
                <div className="inner-div3">
                    <p className='title'>About Us</p>
                    <p><span>Phone Number:</span>  000-000-0000</p>
                    <p><span>Email Address:</span>  info@ECSprovider.com</p>
                    <p className='address'><span>Office Address:</span>  Office Number 000,abc street No 11, 00 Block,Sector V, USA</p>
                    <div className='icons'>
                        <div className='icon'>
                            <img src={facebook} alt="" />
                        </div>
                        <div className='icon'>
                            <img src={instagram} alt="" />
                        </div>
                        <div className='icon'>
                            <img src={linkedin} alt="" />
                        </div>
                        <div className='icon'>
                            <img src={facebook} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_footer">
                <p className='footer_footer_text'><span>© 2023 </span><span className='red'>Ecs Provider</span><span>. All Rights Reserved</span></p>
                <div className='aboutus'>
                    <p>FAQs</p>
                    <p>Careers</p>
                    <p>Contact Us</p>
                    <p>Terms and Conditions</p>
                    <p>Privacy Policy</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
