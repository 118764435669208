import React, { useState, useRef } from 'react';
import '../CSS/Video.css';
import videoposter from '../assets/videothumbnail.png';
import movie from '../assets/video.mp4';

const VideoComponent = () => {
  const [playpause, setPlayPause] = useState('pause');
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleButtonClick = () => {
    if (playpause === 'play') {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlayPause(playpause === 'play' ? 'pause' : 'play');
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className='main_video_container'
    >
      <h1>ECS Provider</h1>
      <h1 className='red'>Taxi and Limousine Services</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled.
      </p>
      <div className='video_container' onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <video
          ref={videoRef}
          width='1178px'
          height='584'
          controls
          // autoPlay
          poster={videoposter}
          onClick={handleVideoClick}
          className={playpause === 'play' && !isHovered ? 'extend' : ''}
        >
          <source src={movie} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div
          className={`playpause_btn_container ${playpause === 'play' && !isHovered ? 'hidden' : 'visible'
            }`}
        >
          <button onClick={handleButtonClick} className={playpause}>
            {playpause === 'play' ? (
              <div className='pause'></div>
            ) : (
              <div className='play'></div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;

