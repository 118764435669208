import React from "react";
import '../CSS/page2.css';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import YourComponent from "../components/Paymentcomponent";

function Paymentpage(){
    return(
        <div className="payment_page">
            <NavBar/>
            <YourComponent/>
            <Footer/>
        </div>
    )
}

export default Paymentpage;