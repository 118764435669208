import React from 'react';
import '../CSS/Bookwithus.css';
import mainimage from '../assets/bodyimage.png'
import icon1 from '../assets/Icon1.png'
import icon2 from '../assets/Icon2.png'
import icon3 from '../assets/Icon3.png'
import icon4 from '../assets/Icon4.png'

const Withus = () => {
  return (
    <div className="main_body_container">
      <div className="image-container">
        <img src={mainimage} alt="Your Image" />
      </div>
      <div className="content-container">
        <h1><span className='headingpart1'>Why Book</span><span className='headingpart2'>With Us?</span></h1>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
        <div className="grid-container">
          <div className="grid-item">
            <div className='header'>
              <h3>Global Availability</h3>
              <div>
                <img src={icon1} alt="" />
              </div>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
          </div>
          <div className="grid-item">
            <div className='header'>
              <h3>Reservation By Phone/Email</h3>
              <div>
                <img src={icon2} alt="" />
              </div>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
          </div>
          <div className="grid-item">
            <div className='header'>
              <h3>No Hidden Cost</h3>
              <div>
                <img src={icon4} alt="" />
              </div>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
          </div>
          <div className="grid-item">
            <div className='header'>
              <h3>24x7 Services</h3>
              <div>
                <img src={icon3} alt="" />
              </div>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withus;
