import React,{useState, useEffect} from 'react';
import '../CSS/Pymenycomponent.css';
// import Modal from 'react-bootstrap/Modal';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/Logo.png';
import car from '../assets/AudiA6.png'
import rightredarrow from '../assets/rightredarrow.png'

const YourComponent = () => {
    const navigate = useNavigate();
    const location1 = useLocation();
    // const [msg, setmsg] = useState('')
    const [forminputs, setforminpts] = useState({})
    var [search] = new useSearchParams(location1.search)
    var inputvalues = { from: search.get('from'), to: search.get('to'), pickdate: search.get('pickdate'), picktime: search.get('picktime'), passenger: search.get('passenger'), name: search.get('name'), fare: search.get('fare')}
    const handleclicklogo = ()=>{
        navigate({
            pathname:'/'
        })
    };

    const handleInputChange = (inputKey, value) => {
        setforminpts((prevInputs) => ({
            ...prevInputs,
            [inputKey]: value,
        }));
    };

    useEffect(() => {
        console.log(inputvalues)
        navigate({ pathname: location1.pathname })
    }, []);

    const handlebookbtn = async () =>{
        const store = await axios.post(`https://ecs.equipmentrecycle.com/api/booking-and-customer?customer_email=${forminputs.customer_email}&customer_first_name=${forminputs.customer_first_name}&customer_last_name=${forminputs.customer_last_name}&airline_name=${forminputs.airplane_name}&flight_number=${forminputs.flight_number}&driver_pick_up_sign=${forminputs.driver_pick_sign}&additional_info=${forminputs.additional}&pickuptime=${inputvalues.picktime}&pickupdate=${inputvalues.pickdate}&from=${inputvalues.from}&to=${inputvalues.to}&passengers=${inputvalues.passenger}&car_name=${inputvalues.name}`);
        // setmsg(store.data.msg)
        alert('Your ride has been booked. Thankyou');
        window.location = '/';
    }
    // const [showsignin, setShowsignin] = useState(false);
    // const [showsignup, setShowsignup] = useState(false);

    // const handleClose = () => {
    //     setShowsignin(false);
    //     setShowsignup(false);
    // };
    // const handleShowsignin = () => {
    //     setShowsignin(true);
    //     setShowsignup(false);
    // };
    // const handleShowsignup = () => {
    //     setShowsignin(false);
    //     setShowsignup(true);
    // };
    return (
        <div className="payment-main-container">
            <div className="logo-container" onClick={handleclicklogo}>
                <div className="hhh">
                    <div className="logo-background">
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <h1 className='mainheading'>Final Step of Booking</h1>
            <p className='maintext'>Congratulations, you have picked one of our best values. Book today and guarantee this great price.</p>
            <div className="payment_inner_main_container">
                {/* Left Container */}
                <div className="left-container">
                    {/* First Div */}
                    <div className="first-div">
                        <p>{inputvalues.pickdate} : {inputvalues.picktime}</p>
                        <p>{inputvalues.from} <img src={rightredarrow} alt="" /> {inputvalues.to}</p>
                        {/* <div className="arrow"></div> */}
                    </div>

                    {/* Second Div */}
                    <div className="second-div">
                        <img className="car-image" src={car} alt="Car Logo" />
                        <div><p>Sedan</p><p className='bold'>Economy</p></div>
                        <div><p>1 Passengers</p><p className='bold'>${inputvalues.fare} USD</p></div>
                    </div>

                    {/* Third Div */}
                    <div className="third-div">
                        <h2>Who’s Traveling?</h2>
                        <p>Already have an account? <button>Sign In</button> for a faster & easier checkout process.</p>
                        <div className="input-fields">
                            <input type="email" placeholder="Email" onChange={(e) => handleInputChange('customer_email', e.target.value)}/>
                            <input type="text" placeholder="First Name" onChange={(e) => handleInputChange('customer_first_name', e.target.value)}/>
                            <input type="text" placeholder="Last Name" onChange={(e) => handleInputChange('customer_last_name', e.target.value)}/>
                            <div className="mobile-input">
                                <select onChange={(e) => handleInputChange('notype', e.target.value)}>
                                    {/* Add your country codes here */}
                                    <option value="+1">+1</option>
                                    <option value="+44">+44</option>
                                    {/* Add more options if needed */}
                                </select>
                                <input className='mobile' type="text" placeholder="Mobile Number" onChange={(e) => handleInputChange('mobileno', e.target.value)}/>
                            </div>
                        </div>
                    </div>

                    {/* Fourth Div (Flight Information) */}
                    <div className="flight-div">
                        <h2>Flight Information</h2>
                        <p>If you do not know or have a flight, enter in any airline and flight number, then leave instructions about your pickup in the Additional notes field below.</p>
                        <div className="input-fields">
                            <input type="text" placeholder="Airline" onChange={(e) => handleInputChange('airline_name', e.target.value)}/>
                            <input type="text" placeholder="Flight Number" onChange={(e) => handleInputChange('flightnumber', e.target.value)}/>
                        </div>
                    </div>

                    {/* Fifth Div (Driver Information) */}
                    <div className="driver-div">
                        <h2>Driver Information</h2>
                        <div className="input-fields">
                            <input type="text" placeholder="Driver Pick Up Sign" onChange={(e) => handleInputChange('driver_pick_sign', e.target.value)}/>
                            <input type="text" placeholder="Add a note to the driver (Additional requests)" onChange={(e) => handleInputChange('adiitional', e.target.value)}/>
                        </div>
                    </div>

                    {/* ... (Add other Divs if needed) */}
                </div>

                {/* Right Container */}
                <div className="right-container">
                    {/* ... (Previous Right Container Content) */}

                    {/* Sixth Div */}
                    <div className="secure-payment-div">
                        <h2>Secure Payment</h2>
                        <div className='check'>
                            <input type="checkbox" id="instant-confirmation" />
                            <label htmlFor="instant-confirmation">Instant Confirmation</label>
                        </div>
                        <p>By clicking "Book Ride" you agree for auto-registration Terms of Service. Change of plan? No worries with our Cancellation policy.</p>
                        <div className='check'>
                            <input type="checkbox" id="accept-terms" />
                            <label htmlFor="accept-terms">Accepting Terms & Conditions</label>
                        </div>
                        <p>By accepting 'Terms & Condition' you agree to receive informational, promotionals & newsletter emails from LimoFahr Privacy Policy.</p>
                        <button onClick={handlebookbtn}>Book Ride For ${inputvalues.fare} USD</button>
                    </div>

                    {/* Seventh Div */}
                    <div className="trusted-since-div">
                        <h2>TRUSTED SINCE 2019</h2>
                        <p>Pre-book your ride and save! Customers who book with limofahr.com save on average 30% from Taxi or Ride Share hailing at the airport due to surge pricing and other factors.</p>
                        <p>Traveling with children? In most countries, it’s illegal for children under 5 years to ride without a baby seat, child seat, or booster seat. Please let us know how many child safety seats are required, so we can tell your driver ahead of your trip. We appreciate about 24 hours advance notice to arrange this, as child seats are in short supply.</p>
                    </div>
                </div>
            </div>
            {/* <Modal show={showsignin} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input className='modalinput' type="email" placeholder="Email" />
                    <input className='modalinput' type="password" placeholder="Password" />
                    <button className='forgot'>Forgot Password</button>
                    <div className='check'>
                        <input type="checkbox" id="save" />
                        <label htmlFor="save">Save Password</label>
                    </div>
                    <button className='Submit' onClick={handleClose}>Submit</button>
                </Modal.Body>
                <Modal.Footer>
                    <p>Do not have an account with us yet?</p>
                    <button className='Register' onClick={handleShowsignup}>Register Now</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showsignup} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input className='modalinput' type="email" placeholder="Email" />
                    <input className='modalinput' type="text" placeholder="First Name" />
                    <input className='modalinput' type="text" placeholder="Last Name" />
                    <input className='modalinput' type="text" placeholder="Mobile Number" />
                    <input className='modalinput' type="password" placeholder="Password" />
                    <input className='modalinput' type="password" placeholder="Confirm Password" />
                    <button className='Submit' onClick={handleClose}>Submit</button>
                </Modal.Body>
                <Modal.Footer>
                <p>Already have an Account</p>
                    <button className='login' onClick={handleShowsignin}>Login Now</button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
};

export default YourComponent;
