import React, { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import '../CSS/Form.css';
import Logo from '../assets/Logo.png'
// import Background_Image from '../assets/Background.png'
import { LoadScript, Autocomplete } from '@react-google-maps/api';
const libraries = ['places'];

const Form = ({ form_inputs }) => {
    var [inputs, Setinputs] = useState({})
    var [from, setfrom] = useState([])
    var [to, setto] = useState('')
    var [selectedBtn, setSelectedBtn] = useState(form_inputs[0]);
    const handleButtonClick = (btn) => {
        setSelectedBtn(btn);
    };
    var [place, setPlace] = useState(null);

    const onfromChanged = async () => {
        if (place !== null && place.getPlace() !== undefined) {
            const location = place.getPlace().geometry.location;
            // console.log(place.getPlace())
            // console.log('Place changed:', location.lat(), location.lng());
            setfrom([location.lat(), location.lng()])
        }
    }

    const ontoChanged = async () => {
        if (place !== null && place.getPlace() !== undefined) {
            const location = place.getPlace().geometry.location;
            // console.log(location)
            // console.log(place.getPlace().formatted_address)
            // console.log('Place changed:', location.lat(), location.lng());
            setfrom([location.lat(), location.lng()])
            console.log(place.getPlace().formatted_address)
            setto([place.getPlace().formatted_address])
        }
    }

    const handleInputChange = (inputKey, value) => {
        Setinputs((prevInputs) => ({
            ...prevInputs,
            [inputKey]: value,
        }));
    };
    const navigate = useNavigate();
    const handleSubmit = () => {
        // console.log(location)
        // console.log('from', from)
        // console.log('to', to)
        const inputlength = Object.keys(inputs).length;
        if (inputlength > 5) {
            navigate({
                pathname: '/booking',
                search: createSearchParams({
                    picktime: inputs.time,
                    pickdate: inputs.date,
                    passenger: inputs.passenger,
                    from: inputs.from,
                    to: to,
                    location:from
                }).toString(),
                state: inputs
            });
        }
    };


    return (
        <LoadScript
            googleMapsApiKey="AIzaSyCazB18WL2WM_hOpGml34bOwNG-53nLftI"
            libraries={libraries}>
            <div className='main_form_container'>
                <div className="container">
                    <div className="left-div">
                        <div className="logo-container">
                            <div className="logo-background">
                                <div className="logo">
                                    <img src={Logo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="main_text"><h1 className='h1'>FAST AND EASY WAY </h1><h1 className='h2'>TO <span>RENT A CAR</span></h1></div>
                        <div className="text"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley. Lorem Ipsum is simply dummy text of the printing.</p></div>
                        <button className='Click_here'>Click Here</button>
                    </div>

                    <div className="right-div">
                        <div className="form_btns">
                            {form_inputs.map((form_input, index) => (
                                <button
                                    className={`form_btn${index + 1} ${selectedBtn.name === form_input.name ? 'active' : ''}`}
                                    onClick={() => handleButtonClick(form_input)}
                                >
                                    {form_input.name}
                                </button>
                            ))}
                        </div>
                        <div className="form_inputs">
                            {selectedBtn.inputs.map((inputs, indx) => (
                                <div key={indx} className='input'>
                                    {inputs.length === 2 ? (
                                        <div className='input_field'>
                                            {inputs[0].type !== 'location' && inputs[0].type !== 'date' ? (
                                                <label htmlFor={inputs[0].for}>
                                                    <img src={inputs[0].src} alt="" />
                                                    <div className='input_field1'>
                                                        <span>{inputs[0].label}</span>
                                                        <input type={inputs[0].type} id={inputs[0].id} placeholder={inputs[0].placeholder} onChange={(e) => handleInputChange(inputs[0].id, e.target.value)} />
                                                    </div>
                                                </label>
                                            ) : inputs[0].type === 'date' ? (
                                                <label htmlFor={inputs[0].for}>
                                                    <img src={inputs[0].src} alt="" />
                                                    <div className='input_field1'>
                                                        <span>{inputs[0].label}</span>
                                                        <input
                                                            type="text"
                                                            placeholder={inputs[0].placeholder}
                                                            onChange={(e) => handleInputChange(inputs[0].id, e.target.value)}
                                                            onFocus={(e) => (e.target.type = "date")}
                                                            onBlur={(e) => (e.target.type = "text")}
                                                        />
                                                    </div>
                                                </label>
                                            ) : (
                                                <label htmlFor={inputs[0].for}>
                                                    <img src={inputs[0].src} alt="" />
                                                    <div className='input_field1'>
                                                        <span>{inputs[0].label}</span>
                                                        <Autocomplete
                                                            onLoad={(autocomplete) => setPlace(autocomplete)}
                                                            onPlaceChanged={onfromChanged}
                                                        >
                                                            <input
                                                                type="text"
                                                                placeholder={inputs[0].placeholder}
                                                                onChange={(e) => handleInputChange(inputs[0].id, e.target.value)}
                                                            />
                                                        </Autocomplete>
                                                    </div>
                                                </label>

                                            )}
                                            {inputs[1].type !== 'location' && inputs[1].type !== 'time' ? (
                                                <label htmlFor={inputs[1].for}>
                                                    <img src={inputs[1].src} alt="" />
                                                    <div className='input_field1'>
                                                        <span>{inputs[1].label}</span>
                                                        <input type={inputs[1].type} id={inputs[1].id} placeholder={inputs[1].placeholder} onChange={(e) => handleInputChange(inputs[1].id, e.target.value)} />
                                                    </div>
                                                </label>
                                            ) : inputs[1].type === 'time' ? (
                                                <label htmlFor={inputs[1].for} className='second'>
                                                    <img src={inputs[1].src} alt="" />
                                                    <div className='input_field1'>
                                                        <span>{inputs[1].label}</span>
                                                        <input
                                                            type="text"
                                                            placeholder={inputs[1].placeholder}
                                                            onChange={(e) => handleInputChange(inputs[1].id, e.target.value)}
                                                            onFocus={(e) => (e.target.type = "time")}
                                                            onBlur={(e) => (e.target.type = "text")}
                                                        />
                                                    </div>
                                                </label>
                                            ) : (
                                                <label htmlFor={inputs[1].for}>
                                                    <img src={inputs[1].src} alt="" />
                                                    <div className='input_field1'>
                                                        <span>{inputs[1].label}</span>
                                                        <Autocomplete
                                                            onLoad={(autocomplete) => setPlace(autocomplete)}
                                                            onPlaceChanged={ontoChanged}
                                                        >
                                                            <input
                                                                type="text"
                                                                placeholder={inputs[1].placeholder}
                                                                onChange={(e) => handleInputChange(inputs[1].id, e.target.value)}
                                                            />
                                                        </Autocomplete>
                                                    </div>
                                                </label>

                                            )}
                                        </div>
                                    ) : (
                                        <div className='input_field'>
                                            {inputs[0].type !== 'category' ? (
                                                <label htmlFor={inputs[0].for} className='different'>
                                                    <img src={inputs[0].src} alt="" />
                                                    <div className='input_field2'>
                                                        <span>{inputs[0].label}</span>
                                                        <input type={inputs[0].type} id={inputs[0].id} placeholder={inputs[0].placeholder} onChange={(e) => handleInputChange(inputs[0].id, e.target.value)} />
                                                    </div>
                                                </label>
                                            ) : (
                                                <label htmlFor={inputs[0].for} className='different'>
                                                    <img src={inputs[0].src} alt="" />
                                                    <div className='input_field2'>
                                                        <span>{inputs[0].label}</span>
                                                        <select id={inputs[0].id} defaultValue='Economy' onChange={(e) => handleInputChange(inputs[0].id, e.target.value)}>
                                                            <option value='' disabled>Select Category</option>
                                                            <option value={inputs[0].value[0]}>{inputs[0].value[0]}</option>
                                                            <option value={inputs[0].value[1]}>{inputs[0].value[1]}</option>
                                                            <option value={inputs[0].value[2]}>{inputs[0].value[2]}</option>
                                                        </select>
                                                    </div>
                                                </label>
                                            )}
                                        </div>)}
                                </div>
                            ))}
                            <button type="submit" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </LoadScript>
    );
};
export default Form;
