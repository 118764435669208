import React, { useState } from 'react'
import '../CSS/Ourclients.css'
import client from '../assets/client.png'

const OurClients = () => {
    const data = [
        {title: 'Airport Transfers', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. Lorem Ipsum is simply dummy." },
        {title: 'Another Service', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. Lorem Ipsum is simply dummy." },
        {title: 'Another Service', description: 'Another description...' },
        {title: 'Another Service', description: 'Another description...' },
    ];
    const [startIndex, setStartIndex] = useState(0);

    const handleLeftButtonClick = () => {
        setStartIndex((prevIndex) => (prevIndex === 0 ? data.length - 4 : prevIndex - 4));
    };

    const handleRightButtonClick = () => {
        setStartIndex((prevIndex) => (prevIndex === data.length - 4 ? 0 : prevIndex + 4));
    };
    return (
        <div className='clients_main_container'>
            <h1>Our <span>Clients</span></h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
            <div className='row-container'>
                <div className='button_container leftone'><button onClick={handleLeftButtonClick}><i class="arrow left"></i></button></div>
                <div className='grid-container'>
                    {data.slice(startIndex, startIndex + 4).map((item, index) => (
                        <div key={index} className={`grid-item`}>
                            <img src={client} alt="" />
                        </div>
                    ))}
                </div>
                <div className='button_container rightone'><button onClick={handleRightButtonClick}><i class="arrow right"></i></button></div>
            </div>
        </div>
    )
}

export default OurClients;