// import '../CSS/Comments.css'
// import React, { useState } from 'react';

// const CommentComponent = ({ comments }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const commentsPerPage = 3;

//   const indexOfLastComment = currentPage * commentsPerPage;
//   const indexOfFirstComment = indexOfLastComment - commentsPerPage;
//   const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);

//   const paginate = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <div className="comment-component">
//       <h1>What</h1>
//       <h1>People say about us</h1>
//       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
//       <div className="comment-list">
//         {currentComments.map((comment, index) => (
//           <div key={index} className="comment-item">
//             <div className="comment-content">{comment.comment}</div>
//             <div className="comment-owner">Owner {index + 1}</div>
//           </div>
//         ))}
//       </div>
//       <div className="pagination-dots">
//         {Array(Math.ceil(comments.length / commentsPerPage))
//           .fill(null)
//           .map((_, index) => (
//             <span
//               key={index}
//               className={currentPage === index + 1 ? 'active' : ''}
//               onClick={() => paginate(index + 1)}
//             >
//               ●
//             </span>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default CommentComponent;


import React, { useState } from 'react';
import '../CSS/Comments.css';

const CommentComponent = ({ comments }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 3;

  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClass = i <= rating ? 'star-yellow' : 'star-gray';
      stars.push(<span key={i} className={`star ${starClass}`}>★</span>);
    }
    return stars;
  };

  return (
    <div className="comment-component">
      <h1>What</h1>
      <h1 className='red_heading'>People Say About Us</h1>
      <p className='text'>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        when an unknown printer took a galley of type and scrambled.
      </p>
      <div className="comment-list">
        {currentComments.map((comment, index) => (
          <div key={index} className="comment-item">
            <div className="comment-content">
              <p>{comment.comment}</p>
              <div className="inverted-commas-container">
                <div className="inverted-commas" />
              </div>
            </div>
            <div className="comment-owner">
              <img src={comment.image} alt="" />
              <div className='name_rating'>
                <span>{comment.name}</span>
                <div className="rating">{renderStars(comment.rating)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-dots">
        {Array(Math.ceil(comments.length / commentsPerPage))
          .fill(null)
          .map((_, index) => (
            <span
              key={index}
              className={currentPage === index + 1 ? 'active' : ''}
              onClick={() => paginate(index + 1)}
            >
              ●
            </span>
          ))}
      </div>
    </div>
  );
};

export default CommentComponent;

