import React from 'react';
// import { useState } from 'react';
import '../CSS/Services.css';
import service1 from '../assets/service1.png'
import service2 from '../assets/service2.png'
import service3 from '../assets/service3.png'
import service4 from '../assets/service4.png'
import service5 from '../assets/service5.png'
import service6 from '../assets/service6.png'

const Services = () => {
    // const [clickedItem, setClickedItem] = useState(null);

    // const handleItemClick = (item) => {
    //     setClickedItem(item === clickedItem ? null : item);
    // };

    return (
        <div className="main_services_container">
            <div className="content-container">
                <h1><span className='headingpart1'>OUR</span><span className='headingpart2'>SERVICES</span></h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
            </div>
            <div className="grid-container">
                <div className={`grid-item`}>
                    <div className='image_container'>
                        <img src={service1} alt="" />
                    </div>
                    <div className='text'>
                        <h3>Airport Transfers</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    </div>
                </div>
                <div className={`grid-item`}>
                    <div className='image_container'>
                        <img src={service2} alt="" />
                    </div>
                    <div className='text'>
                        <h3>Vip Assistance</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    </div>
                </div>
                <div className={`grid-item`}>
                    <div className='image_container'>
                        <img src={service3} alt="" />
                    </div>
                    <div className='text'>
                        <h3>Private Chauffeurs</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    </div>
                </div>
                <div className={`grid-item`}>
                    <div className='image_container'>
                        <img src={service4} alt="" />
                    </div>
                    <div className='text'>
                        <h3>Events</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    </div>
                </div>
                <div className={`grid-item`}>
                    <div className='image_container'>
                        <img src={service5} alt="" />
                    </div>
                    <div className='text'>
                        <h3>Corporate</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    </div>
                </div>
                <div className={`grid-item`}>
                    <div className='image_container'>
                        <img src={service6} alt="" />
                    </div>
                    <div className='text'>
                        <h3>Trip Service</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
