import React, { useState } from 'react'
import '../CSS/Ourprojects.css'
import project1 from '../assets/project1.png'
import project2 from '../assets/project2.png'
import project3 from '../assets/BMW5.png'
import project4 from '../assets/BMW7.png'

const Ourprojects = () => {
    const data = [
        { image: project1, title: 'Airport Transfers', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. Lorem Ipsum is simply dummy." },
        { image: project2, title: 'Another Service', description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. Lorem Ipsum is simply dummy." },
        { image: project3, title: 'Another Service', description: 'Another description...' },
        { image: project4, title: 'Another Service', description: 'Another description...' }
    ];
    const [startIndex, setStartIndex] = useState(0);

    const handleLeftButtonClick = () => {
        setStartIndex((prevIndex) => (prevIndex === 0 ? data.length - 2 : prevIndex - 2));
    };

    const handleRightButtonClick = () => {
        setStartIndex((prevIndex) => (prevIndex === data.length - 2 ? 0 : prevIndex + 2));
    };
    return (
        <div className='projects_main_container'>
            <div className="container_for_color">
            <h1>Our <span>Projects</span></h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
            <div className='row-container'>
                <div className='button_container leftone'><button onClick={handleLeftButtonClick}><i class="arrow left"></i></button></div>
                <div className='grid-container'>
                    {data.slice(startIndex, startIndex + 2).map((item, index) => (
                        <div key={index} className={`grid-item`}>
                            <div className='image_container'>
                                <img src={item.image} alt={`Service ${startIndex + index + 1}`} />
                            </div>
                            <div className='text'>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <button>Click Here</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='button_container rightone'><button onClick={handleRightButtonClick}><i class="arrow right"></i></button></div>
            </div>
            </div>
        </div>
    )
}

export default Ourprojects;