import React, { useState, useEffect } from 'react'
import '../CSS/Inputfields.css'
import { LoadScript, Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { createSearchParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Logo from '../assets/Logo.png';
import axios from 'axios';
import '../CSS/Carscards.css';
import location from '../assets/location_Icon.png';
import rightredarrow from '../assets/rightredarrow.png'
// import distance from '../assets/between.png'
// import duration from '../assets/back-in-time.png'
import logo from '../assets/Logo.png';
const libraries = ['places'];

const Inputfields = ({ selectedBtn }) => {
    const [carsData, setcardata] = useState([])
    const location1 = useLocation();
    var [search] = new useSearchParams(location1.search)
    var [inputvalues, setinputvalues] = useState({ from: search.get('from'), to: search.get('to'), pickdate: search.get('pickdate'), picktime: search.get('picktime'), passenger: search.get('passenger'), location: search.get('location') })
    // const [inputvalues, setinputvalues] = useState([search.get('')])
    // const [showMore, setShowMore] = useState([]);
    const [map, setMap] = useState(null);
    const [isDataAvailable, setIsDataAvailable] = useState(true);
    const [rend, Setrend] = useState(false)

    // const toggleShowMore = (index) => {
    //     const updatedShowMore = [...showMore];
    //     updatedShowMore[index] = !updatedShowMore[index];
    //     setShowMore(updatedShowMore);
    // };

    useEffect(() => {
        console.log(inputvalues)
        navigate({ pathname: location1.pathname })
        const fetchData = axios.post(`https://ecs.equipmentrecycle.com/api/vehicles?pickup=${inputvalues.from}&dropoff=${inputvalues.to}&picktime=${inputvalues.picktime}&pickdate=${inputvalues.pickdate}&passengers=${inputvalues.passenger}`);
        // const fetchData = axios.post(`https://ecs.equipmentrecycle.com/api/vehicles?pickup=Riyadh&dropoff=Riyadh&picktime=12:00&pickdate=2024-02-03&passengers=1`);
        fetchData.then(response => {
            console.log(response);
            if (response.data.success === true) {
                const data = response.data.result;
                setcardata(data)
                setIsDataAvailable(true)
            } else {
                setIsDataAvailable(false)
                // console.log(response.data)
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
        // Setrend(true)
    }, []);

    const mapContainerStyle = {
        width: '100%',
        height: '100%',
    };

    const center = {
        lat: 24.7248315, // Replace with your desired latitude
        lng: 46.4928701, // Replace with your desired longitude
    };
    const [place, setPlace] = useState(null);

    const onPlaceChanged = async () => {
        if (place !== null && place.getPlace() !== undefined) {
            const location = place.getPlace().geometry.location;
            console.log('Place changed:', location.lat(), location.lng());
        }
        console.log(place)
    };
    const navigate = useNavigate();
    const handleclicklogo = () => {
        navigate({
            pathname: '/'
        })
    };
    const handleButtonClick = (name, fare) => {
        if(rend === true){
            navigate({
                pathname: '/payment',
                search: createSearchParams({
                    picktime: inputvalues.picktime,
                    pickdate: inputvalues.pickdate,
                    passenger: inputvalues.passenger,
                    from: inputvalues.from,
                    to: inputvalues.to,
                    name: name,
                    fare:fare
                }).toString(),
                state: inputvalues
            });
        }
    };
    return (
        <div>
            <LoadScript
                googleMapsApiKey="AIzaSyCazB18WL2WM_hOpGml34bOwNG-53nLftI"
                libraries={libraries}
            >


                <div className="main_booking_form_container">
                    <div className="logo-container" onClick={handleclicklogo}>
                        <div className="hhh">
                            <div className="logo-background">
                                <div className="logo">
                                    <img src={Logo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="booking_form_inputs">
                        {selectedBtn.inputs.map((inputs, indx) => (
                            <div key={indx} className='input'>
                                {inputs.length === 2 ? (
                                    <div className='input_field'>
                                        {inputs[0].type !== 'location' && inputs[0].type !== 'date' && inputs[0].type !== 'time' ? (
                                            <label htmlFor={inputs[0].for}>
                                                <div className='input_field1'>
                                                    <input type={inputs[0].type} id={inputs[0].id} placeholder={inputs[0].placeholder} />
                                                    <div className="underline"></div>
                                                </div>
                                            </label>
                                        ) : inputs[0].type !== 'date' && inputs[0].type !== 'time' ? (
                                            <label htmlFor={inputs[0].for}>
                                                <div className='input_field1'>
                                                    <Autocomplete
                                                        onLoad={(autocomplete) => setPlace(autocomplete)}
                                                        onPlaceChanged={onPlaceChanged}
                                                    >
                                                        <div>
                                                            <input
                                                                type="text"
                                                                id={inputs[0].id}
                                                                placeholder={inputs[0].placeholder}
                                                            // value={inputvalues[id]}
                                                            />
                                                            <div className="underline"></div>
                                                        </div>
                                                    </Autocomplete>
                                                    <div className="underline"></div>
                                                </div>
                                            </label>

                                        ) : (
                                            <label htmlFor={inputs[0].for}>
                                                <div className='input_field1'>
                                                    <input
                                                        type="text"
                                                        placeholder={inputs[0].placeholder}
                                                        onChange={(e) => console.log(e.target.value)}
                                                        onFocus={(e) => (e.target.type = "date")}
                                                        onBlur={(e) => (e.target.type = "text")}
                                                    />
                                                    <div className="underline"></div>
                                                </div>
                                            </label>
                                        )}
                                        {inputs[1].type !== 'location' && inputs[1].type !== 'time' ? (
                                            <label htmlFor={inputs[1].for} className='second'>
                                                <div className='input_field1'>
                                                    <input type={inputs[1].type} id={inputs[1].id} placeholder={inputs[1].placeholder} />
                                                    <div className="underline"></div>
                                                </div>
                                            </label>
                                        ) : inputs[1].type !== 'date' && inputs[1].type !== 'time' ? (
                                            <label htmlFor={inputs[1].for} className='second'>
                                                <div className='input_field1'>
                                                    <Autocomplete
                                                        onLoad={(autocomplete) => setPlace(autocomplete)}
                                                        onPlaceChanged={onPlaceChanged}
                                                    >
                                                        <div>
                                                            <input
                                                                type="text"
                                                                placeholder={inputs[1].placeholder}
                                                            />
                                                            <div className="underline"></div>
                                                        </div>
                                                    </Autocomplete>
                                                    <div className="underline"></div>
                                                </div>
                                            </label>

                                        ) : (
                                            <label htmlFor={inputs[1].for} className='second'>
                                                <div className='input_field1'>
                                                    <input
                                                        type="text"
                                                        placeholder={inputs[1].placeholder}
                                                        onChange={(e) => console.log(e.target.value)}
                                                        onFocus={(e) => (e.target.type = "time")}
                                                        onBlur={(e) => (e.target.type = "text")}
                                                    />
                                                    <div className="underline"></div>
                                                </div>
                                            </label>
                                        )}
                                    </div>
                                ) : (
                                    <div className='input_field'>
                                        {inputs[0].type !== 'category' ? (
                                            <label htmlFor={inputs[0].for} className='different'>
                                                <div className='input_field2'>
                                                    <input type={inputs[0].type} id={inputs[0].id} placeholder={inputs[0].placeholder} />
                                                    <div className="underline"></div>
                                                </div>
                                            </label>
                                        ) : (
                                            <label htmlFor={inputs[0].for} className='different2'>
                                                <div className='input_field3'>
                                                    <select id={inputs[0].id} defaultValue='Economy'>
                                                        <option value='' disabled style={{ color: 'black' }}>Select Category</option>
                                                        <option value={inputs[0].value[0]} style={{ color: 'black' }}>{inputs[0].value[0]}</option>
                                                        <option value={inputs[0].value[1]} style={{ color: 'black' }}>{inputs[0].value[1]}</option>
                                                        <option value={inputs[0].value[2]} style={{ color: 'black' }}>{inputs[0].value[2]}</option>
                                                    </select>
                                                    <div className="underline"></div>
                                                </div>
                                            </label>
                                        )}
                                    </div>)}
                            </div>
                        ))}
                        <button type="submit">Update</button>
                    </div>
                </div>
                <div className="cars-cards-container">
                    {isDataAvailable ? (
                        <div>
                            <h1 className='mainheading'>Choose Your Vehicle</h1>
                            <div className="first-div">
                                <p>{inputvalues.pickdate} : {inputvalues.picktime}</p>
                                <p>{inputvalues.from} <img src={rightredarrow} alt="" /> {inputvalues.to}</p>
                            </div>
                            {/* {carsData.map((carType, index) => (
                                <div key={index} className="cars-type">
                                    <h1>{`${carType.type === 'First_class' ? 'First Class' : carType.type} Cars`}</h1>
                                    {carType.data.slice(0, showMore[index] ? undefined : 1).map((car, carIndex) => (
                                        <div key={carIndex} className="car-card">
                                            <div className="car-header">
                                                <img className="car-image" src={car.image} alt={car.name} />
                                                <div className="logo-container">
                                                    <div className="hhh">
                                                        <div className="logo-background">
                                                            <div className="logo">
                                                                <img src={logo} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="passengers-price">
                                                <p className="car-type">{`${carType.type} ${car.type}`}</p>
                                                <p>{`${car.max} passengers`}</p>
                                            </div>
                                            <div className="passengers-price">
                                                <p className="example">{`e.g. ${car.example} or similar cars`}</p>
                                                <p className='car-type'>{`$533 USD`}</p>
                                            </div>
                                            <p>{`Max ${car.max} passengers`}</p>
                                            <div className="button_container"><button className="book-now">Book Now</button></div>
                                            <div className="additional-info">
                                                <div>Curbside Pick Up</div>
                                                <div>45 Minutes Waiting Time</div>
                                                <div>Flight Delays Info</div>
                                            </div>
                                        </div>
                                    ))}
                                    {carType.data.length > 1 && (
                                        <div className="toggle-button">
                                            <button onClick={() => toggleShowMore(index)}>
                                                {showMore[index] ? `Less ${carType.type}` : `More ${carType.type}`}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))} */}
                            {carsData.map((car, carIndex) => (
                                <div key={carIndex} className="car-card">
                                    <div className="car-header">
                                        <img className="car-image" src={car.img} alt={car.name} />
                                        <div className="logo-container">
                                            <div className="hhh">
                                                <div className="logo-background">
                                                    <div className="logo">
                                                        <img src={logo} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="passengers-price">
                                        <p className="car-type">{`${car.type}`}</p>
                                        <p>{`${inputvalues.passenger} passengers`}</p>
                                    </div>
                                    <div className="passengers-price">
                                        <p className="example">{`${car.color}`}</p>
                                        <p className='car-type'>{`$${car.fare}`}</p>
                                    </div>
                                    <p>{`Max 5 passengers`}</p>
                                    <div className="button_container"><button className="book-now" onClick={()=>{
                                        Setrend(true)
                                        handleButtonClick(car.name, car.fare)
                                    }}>Book Now</button></div>
                                    <div className="additional-info">
                                        <div>Curbside Pick Up</div>
                                        <div>45 Minutes Waiting Time</div>
                                        <div>Flight Delays Info</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>No cars available for the given details</div>
                    )}


                    {/* Map Container */}
                    <div className="map-container">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={center}
                            zoom={12}
                            onLoad={(map) => setMap(map)}
                        >
                            {/* Add markers or other map components here if needed */}
                            {map && <Marker position={center} />}
                        </GoogleMap>
                        <div className='map_details'>
                            <div>
                                <img src={location} alt="" />
                                <p>{inputvalues.from}</p>
                            </div>
                            <div>
                                <img src={location} alt="" />
                                <p>{inputvalues.to}</p>
                            </div>
                            {/* <div>
                                <img src={distance} alt="" />
                                <p>30km</p>
                            </div>
                            <div>
                                <img src={duration} alt="" />
                                <p>2.5 hrs</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </LoadScript>
        </div>

    )
}

export default Inputfields;